var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-tooltip",
    { key: _vm.caseEventUuid, attrs: { tooltip: _vm.caseEventUuid } },
    [
      _c(
        "RouterLink",
        {
          staticClass: "emobg-link-primary emobg-body-2",
          attrs: {
            to: {
              name: _vm.cases.caseEvents.detail,
              params: {
                caseEventUuid: _vm.caseEventUuid,
              },
            },
          },
        },
        [_vm._v(" #" + _vm._s(_vm.result.id) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }