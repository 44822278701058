<script>
import { get } from 'lodash';
import { FALLBACK_MESSAGE, isFalsyString } from '@emobg/web-utils';

import fleet from '../../../Carsharing/router/FleetRouterMap';

export default {
  name: 'VehicleLink',
  props: {
    result: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    plateNumber() {
      return get(this, 'result.vehicle_license_plate');
    },
    hasVehicleData() {
      return !isFalsyString(this.result.vehicle_license_plate) && this.result.vehicle_uuid;
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.fleet = fleet;
  },
};
</script>
<template>
  <ui-tooltip
    :key="plateNumber"
    :tooltip="plateNumber"
  >
    <RouterLink
      v-if="hasVehicleData"
      :to="{
        name: fleet.vehicles.detail.details,
        params: {
          vehicle_uuid: result.vehicle_uuid
        }
      }"
      class="emobg-link-primary emobg-body-2"
    >
      #{{ plateNumber }}
    </RouterLink>
    <span v-else>
      {{ FALLBACK_MESSAGE.dash }}
    </span>
  </ui-tooltip>
</template>
