import { render, staticRenderFns } from "./CaseDetailsLink.vue?vue&type=template&id=556884a2&"
import script from "./CaseDetailsLink.vue?vue&type=script&lang=js&"
export * from "./CaseDetailsLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('556884a2')) {
      api.createRecord('556884a2', component.options)
    } else {
      api.reload('556884a2', component.options)
    }
    module.hot.accept("./CaseDetailsLink.vue?vue&type=template&id=556884a2&", function () {
      api.rerender('556884a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/CasesServices/CaseEvents/components/CaseDetailsLink.vue"
export default component.exports