var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ui-tooltip",
    { key: _vm.plateNumber, attrs: { tooltip: _vm.plateNumber } },
    [
      _vm.hasVehicleData
        ? _c(
            "RouterLink",
            {
              staticClass: "emobg-link-primary emobg-body-2",
              attrs: {
                to: {
                  name: _vm.fleet.vehicles.detail.details,
                  params: {
                    vehicle_uuid: _vm.result.vehicle_uuid,
                  },
                },
              },
            },
            [_vm._v(" #" + _vm._s(_vm.plateNumber) + " ")]
          )
        : _c("span", [_vm._v(" " + _vm._s(_vm.FALLBACK_MESSAGE.dash) + " ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }