<script>
import { get } from 'lodash';
import { FALLBACK_MESSAGE } from '@emobg/web-utils';
import cases from '../../router/CasesServicesRouterMap';

export default {
  name: 'CaseDetailsLink',
  props: {
    result: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  computed: {
    caseEventUuid() {
      return get(this, 'result.uuid');
    },
  },
  created() {
    this.FALLBACK_MESSAGE = FALLBACK_MESSAGE;
    this.cases = cases;
  },
};
</script>
<template>
  <ui-tooltip
    :key="caseEventUuid"
    :tooltip="caseEventUuid"
  >
    <RouterLink
      :to="{
        name: cases.caseEvents.detail,
        params: {
          caseEventUuid
        }
      }"
      class="emobg-link-primary emobg-body-2"
    >
      #{{ result.id }}
    </RouterLink>
  </ui-tooltip>
</template>
