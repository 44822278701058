import { COLORS, GRAYSCALE } from '@emobg/vue-base';

export const PRIORITIES = {
  low: 'low',
  normal: 'normal',
  high: 'high',
  urgent: 'urgent',
};

export const PRIORITIES_COLOR = {
  [PRIORITIES.high]: COLORS.moderate,
  [PRIORITIES.low]: GRAYSCALE.ground,
  [PRIORITIES.normal]: COLORS.warning,
  [PRIORITIES.urgent]: COLORS.danger,
};

